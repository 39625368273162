import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import {useStrapiStatic} from "@hooks/useStrapiStatic";

export const PricingTable: React.FC = () => {

    const data = useStaticQuery(graphql`
        query {
            allStrapiService(sort: {fields: order, order: ASC}) {
                nodes {
                    title
                    price
                    nextPrice
                    strapi_id
                    showPrice
                }
            }
        }
    `);

    return (
        <div className="table-content table-responsive">
            <div className={"d-flex justify-content-between table-header-description"}>
                <span>Zabieg</span>
                <span>Koszt</span>
            </div>
            <table className="table">
                <tbody>
                {
                    data.allStrapiService.nodes.map((service) => (
                        <tr key={service.id} className={service.showPrice ? 'show-price-row' : ''}>
                            <td colSpan={!service.showPrice ? 2 : 1} className={"text-start"}>{service.title}</td>

                            { service.showPrice && <td style={{width: "180px"}}>{service.price} { useStrapiStatic('currency') } </td> }
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <span className={"pricing-table-text"}>
                <ul>
                    <li>
                        W cenie wizyty jest liczona każda usługa, a pacjent jest traktowany kompleksowo.
                    </li>
                </ul>
            </span>
        </div>
    );
}