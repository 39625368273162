import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import {graphql, useStaticQuery} from "gatsby";
import ArticlesGrid from "@components/articles/articles-grid";
import Pager from "@components/pager/pager";
import {PricingTable} from "@components/services/pricing-table";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";

const PricingPage: React.FC<PageProps> = (params: any) => {

    const data = useStaticQuery(graphql`
        query {
            allStrapiService {
                nodes {
                    id
                    title
                    price
                    nextPrice
                }
            }
        }
    `);

    return (
        <Layout>
            <main>
                <CommonPageHeader title="Cennik" subtitle="cennik" />
                <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                    <div className="container">
                        <div className="row">
                            <div className={"col-12"}>
                                <PricingTable />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default PricingPage


export const Head: HeadFC = () => <title>Cennik - Fizjoproject Kiekrz</title>
